<template>
  <div id="bid-summary-container" class="card p-2 mt-3">
    <ul class="list-inline edit-links pull-right">
      <li class="list-inline-item">
        <change-leave-year @change="fetchBids()"/>
      </li>
      <li class="list-inline-item">
        <a href="#" @click.prevent="print = true">
          <i class="fas fa-print text-info"></i>
        </a>
      </li>
    </ul>

    <h5>Bid Summary</h5>
    <p>All bids entered for all controllers assigned to the current area. </p>

    <div class="row">
      <div class="col">
        <ul class="list-inline">
          <li class="list-inline-item">
            <el-dropdown trigger="click" class="mt-0">
              <span class="el-dropdown-link">
                  <n-button round
                            type="info"
                            class="bid-button small my-0"
                            slot="title">
                    <strong>Sort:</strong> {{ sort.toUpperCase() }} <i class="fas fa-caret-down ml-2"/>
                  </n-button>
              </span>

              <el-dropdown-menu>
                <el-dropdown-item v-for="option in filteredOptions" :key="option" @click.native="sort = option">
                  {{ option.toUpperCase() }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li class="list-inline-item" v-if="sort !== 'seniority'">
              <a href="#" class="d-block ml-1 font-weight-bold"
                 @click.prevent="sortDirection = sortDirection === 'asc' ? 'desc' : 'asc' ">
                <i class="fas mr-1" :class="sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down'"/>
                <small>{{ sortDirection.toUpperCase() }}</small>
              </a>
          </li>
          <li class="list-inline-item">
            <el-dropdown trigger="click" class="mt-0">
              <span class="el-dropdown-link">
                  <n-button round
                            type="info"
                            class="bid-button small my-0"
                            slot="title">
                    <strong>Round</strong> {{ round ? ': ' + round.toUpperCase() : '' }} <i class="fas fa-caret-down ml-2"/>
                  </n-button>
              </span>

              <el-dropdown-menu>
                <el-dropdown-item @click.native="round = null" v-if="round !== null">
                  All Rounds
                </el-dropdown-item>
                <el-dropdown-item v-for="(r, i) in filteredRounds" :key="i" @click.native="round = r.name">
                  {{ r.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li class="list-inline-item">
            <el-dropdown trigger="click" class="mt-0">
              <span class="el-dropdown-link">
                  <n-button round
                            type="info"
                            class="bid-button small my-0"
                            slot="title">
                    <strong>Leave Type</strong> {{ type ? ': ' + selectedType.toUpperCase() : '' }} <i
                    class="fas fa-caret-down ml-2"/>
                  </n-button>
              </span>

              <el-dropdown-menu>
                <el-dropdown-item @click.native="type = null" v-if="type !== null">
                  All Leave Types
                </el-dropdown-item>
                <el-dropdown-item v-for="(t, i) in filteredTypes" :key="i" @click.native="type = t.id">
                  {{ t.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <table class="table results">
          <thead>
          <tr class="d-flex">
            <th class="col-3 col-md-2">Controller</th>
            <th class="col-3 col-md-2">Line</th>
            <th class="col-6 col-md-8">Leave</th>
          </tr>
          </thead>
          <tr v-for="member in filteredBids" :key="member.id" class="d-flex">
            <td class="col-3 col-md-2">{{ member.name_lf }} ({{ member.init | uppercase }})</td>
            <td class="col-3 col-md-2">
              <ul class="list-unstyled">
                <li v-for="(line, i) in linebids(member.bids)" :key="i">
                  <span class="d-block font-weight-bold">{{ line.linegroup }}</span>
                  {{ line.name }}
                </li>
              </ul>
            </td>
            <td class="col-6 col-md-8">
              <ul class="list-unstyled mb-0">
                <li v-for="leave in leavebids(member.bids)" :key="leave.id">
                  <h6 class="mb-0" v-if="leave.grouped">{{ leave.round_name }}</h6>
                  <template v-for="(bid, type_id) in leave.grouped">
                    <div class="d-block mb-1" :key="bid.calendar_id">
                      <div class="summary-leave-type"
                           :style="'background-color: ' + ( getType(type_id) ? getType(type_id).bgcolor : '#fff')">
                        {{ getType(type_id) ? getType(type_id).name : '' }}
                      </div>
                      <p class="m-1">{{ formatDates(bid) }}</p>
                    </div>
                  </template>
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <print element-id="bid-summary-container"
           :title="title"
           :trigger="print" @done="print = false"></print>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Print } from '@/components/stateless'
import { ChangeLeaveYear } from '@/components'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const BidRepository = RepositoryFactory.get('bid')

export default {
  name: 'bid-summary',
  components: {
    ChangeLeaveYear,
    Print
  },
  data () {
    return {
      members: '',
      print: false,
      sortOptions: [
        'seniority',
        'name'
      ],
      sort: 'seniority',
      sortDirection: 'asc',
      round: null,
      type: null
    }
  },
  computed: {
    ...mapGetters({
      area: 'area/area',
      facility: 'facility/facility',
      viewYear: 'facility/viewYear',
      is: 'auth/is',
      types: 'leave/types',
      rounds: 'round/rounds'
    }),
    title () {
      return this.area.name + ' Area Bid Summary'
    },
    filteredBids () {
      // Configure the sort definitions for each option
      const sortDefs = {
        name: ['lname', 'fname'],
        seniority: ['NATCA_date', 'BU_date', 'SCD_date', 'EOD_date']
      }

      return _.orderBy(this.members, sortDefs[this.sort], this.sortDirection)
    },
    filteredOptions () {
      return _.filter(this.sortOptions, o => o !== this.sort)
    },
    filteredRounds () {
      return _.filter(this.rounds, r => r.status !== 'new' && r.name !== this.round && r.name !== 'Line Round')
    },
    filteredTypes () {
      return _.filter(this.types, t => t.id !== this.selType && t.id !== this.type)
    },
    selectedType () {
      return _.find(this.types, { id: this.type }).name
    }
  },
  methods: {
    /** Retrieve crews */
    fetchBids () {
      BidRepository.getBidSummary(this.viewYear)
        .then(r => {
          this.members = r.data
        })
    },
    /** Simplify date display */
    formatDates (dates) {
      return _.flatMap(dates, d => this.$options.filters.utcDisplay(d.calendar_id)).join(', ')
    },
    getType (typeID) {
      return _.find(this.types, { id: parseInt(typeID) })
    },
    linebids (bids) {
      const linebid = _.filter(bids, b => b.line.length)[0]
      return linebid ? linebid.line : null
    },
    leavebids (bids) {
      // Filter down to only leave bids for the selected round
      const leavebid = _.filter(bids, b => b.leave.length && (!this.round || (this.round && this.round === b.round_name)))

      // Group the leave bids by type and filter
      _.forEach(leavebid, (bid, i) => {
        const filter = _.filter(bid.leave, t => !this.type || (t.leave_type_id === this.type))
        leavebid[i].grouped = filter.length ? _.groupBy(filter, 'leave_type_id') : null
      })
      return leavebid
    }
  },
  created () {
    this.fetchBids()
  }
}
</script>
<style>
#bid-summary-container .edit-links {
  margin-top: -45px;
  margin-right: 0;
}
</style>
